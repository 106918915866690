"use client";

import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import { useEffect } from "react";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <div style={{ padding: "20px", textAlign: "center" }}>
          <h1>Something went wrong!</h1>
          {process.env.NODE_ENV === "development" && (
            <>
              <p>Error: {error.message}</p>
              <pre style={{ whiteSpace: "pre-wrap", textAlign: "left" }}>
                {error.stack}
              </pre>
            </>
          )}
          <NextError statusCode={500} />
        </div>
      </body>
    </html>
  );
}
